import React from 'react'
import { LivesQuery } from '../../types/graphql-types'
import { Box, Typography, Link as MuiLink, makeStyles } from '@material-ui/core'
import { SubSection, Paragraph, Section } from '../components/Typography'
import { render } from '../utils/render'
import { moment } from '../utils/moment'

const useStyles = makeStyles(theme => ({
  titleWithSub: { marginTop: 0 },
  paragraph: { marginLeft: theme.spacing(2) },
}))

const LiveItem: React.FC<LivesQuery['allMicrocmsLives']['edges'][number]> = ({
  node,
  previous,
}) => {
  const classes = useStyles()
  const year = moment(node.date).year()
  return (
    <Box
      key={node.livesId}
      mb={4}
      id={year !== moment(previous?.date).year() ? `live_${year}` : undefined}
    >
      {node.title_sub ? (
        <>
          <Typography variant="caption" display="inline">
            {node.title_sub}
          </Typography>
          <Section className={classes.titleWithSub}>{node.title}</Section>
        </>
      ) : (
        <Section>{node.title}</Section>
      )}
      <Typography variant="subtitle1" color="textSecondary">
        {moment(node.date).format('YYYY/MM/DD (ddd)')}
      </Typography>

      <Box mb={2}>
        <SubSection>Place</SubSection>
        <Paragraph className={classes.paragraph}>
          {node.link ? (
            <MuiLink href={node.link} color="inherit" underline="always">
              {node.place}
            </MuiLink>
          ) : (
            node.place
          )}
        </Paragraph>
      </Box>

      {(node.open || node.start) && (
        <Box mb={2}>
          <SubSection>Time</SubSection>
          <Paragraph className={classes.paragraph}>
            {[
              node.open && `開場：${node.open}`,
              node.start && `開演：${node.start}`,
            ]
              .filter(n => !!n)
              .join('　')}
          </Paragraph>
        </Box>
      )}

      {node.prices && node.prices.length > 0 && (
        <Box mb={2}>
          <SubSection>Charge</SubSection>
          <Paragraph className={classes.paragraph}>
            {node.prices.map(price => (
              <React.Fragment key={price?.name}>
                {price?.name}：{price?.price?.toLocaleString('ja-JP')}
                円
                <br />
              </React.Fragment>
            ))}
          </Paragraph>
        </Box>
      )}
      {node.actors && node.actors.length > 0 && (
        <Box>
          <SubSection>Live Act</SubSection>
          <ul style={{ marginTop: 0 }}>
            {node.actors.map(actor => (
              <li key={actor?.name}>
                <Typography>{actor?.name}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
      {node.others && <Box>{render(node.others)}</Box>}
    </Box>
  )
}

export default LiveItem
