import React, { useMemo } from 'react'
import { PageProps, graphql, Link } from 'gatsby'
import { LivesQuery } from '../../types/graphql-types'
import Layout from '../components/Layout'
import { Typography, Link as MuiLink, Box } from '@material-ui/core'
import { Chapter, Title, Paragraph } from '../components/Typography'
import { uniq } from 'lodash'
import LiveItem from '../components/LiveItem'
import { moment } from '../utils/moment'

const Live: React.FC<PageProps<LivesQuery>> = ({ data }) => {
  const now = useMemo(() => moment(), [])
  const { past, future } = data.allMicrocmsLives.edges.reduce(
    (prev, edge) => {
      return moment(edge.node.date).isSameOrAfter(now, 'day')
        ? { ...prev, future: [...prev.future, edge] }
        : { ...prev, past: [...prev.past, edge] }
    },
    { past: [], future: [] } as {
      [x: string]: LivesQuery['allMicrocmsLives']['edges']
    }
  )
  return (
    <Layout path="Live">
      <Title>Live</Title>
      <Box mb={8}>
        <Chapter>開催予定のライブ</Chapter>
        {future.length > 0 ? (
          <Box>
            <Paragraph>
              <MuiLink component={Link} to="/contact/torioki">
                取り置きはこちらから！
              </MuiLink>
            </Paragraph>
            {future.map(props => (
              <LiveItem key={props.node.livesId} {...props} />
            ))}
          </Box>
        ) : (
          <Paragraph>現在開催予定のライブはありません。</Paragraph>
        )}
      </Box>
      <Chapter>過去のライブ</Chapter>
      <ul>
        {uniq(past.map(({ node }) => moment(node.date).year())).map(year => (
          <li key={year}>
            <Typography>
              <MuiLink component={Link} to={`#live_${year}`}>
                {year}
              </MuiLink>
            </Typography>
          </li>
        ))}
      </ul>
      {past.map(props => (
        <LiveItem key={props.node.livesId} {...props} />
      ))}
    </Layout>
  )
}
export const query = graphql`
  query Lives {
    allMicrocmsLives(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          date
          title_sub
          prices {
            name
            price
          }
          actors {
            name
          }
          link
          open
          others
          place
          price_remark
          start
          livesId
        }
        previous {
          date
        }
      }
    }
  }
`
export default Live
